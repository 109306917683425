footer {
    display: flex;
    border-top: 1px solid grey;
    padding-top: 30px;
    position: relative;
    background: white;
}

footer .heading i {
    font-size: 22px;
    color: var(--black);
    margin-right: 10px;
}

footer h4 {
    font-size: 18px;
    margin-bottom: 5px;
    margin-bottom: 10px;
}

footer li,
footer p {
    font-size: 13px;
}

footer li img {
    width: 20%;
    height: 40px;
    margin-right: 10px;
}

footer .logo,
footer .responsiveLogo {
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

footer .logo p {
    width: 80%;
    text-align: center;
}

footer .logo img,
footer .responsiveLogo img {
    width: 60%;
}

footer .about {
    width: 30%;
}

footer .product,
footer .heading {
    width: 30%;
}

footer .heading {
    padding: 0 1%;
}

footer .heading p:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 5px 0;
}

footer .product li {
    display: flex;
    margin-bottom: 10px;
}

footer li {
    margin: 10px 0;
}

footer .product li p {
    width: 70%;
}

footer .responsiveLogo {
    display: none;
}

.copyright {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 15px;
}

.copyright p {
    font-size: 13px;
}

@media (min-width: 1300px) and (max-width:1350px) {}

@media (min-width: 1008px) and (max-width: 1300px) {}

@media (min-width: 641px) and (max-width: 1007px) {
    footer {
        flex-direction: column;
        align-items: center;
        gap: 40px;
        text-align: center;
    }

    footer .product,
    footer .logo {
        display: none;
    }

    footer .responsiveLogo {
        display: block;
        display: flex;
    }

    footer div {
        width: 100%;
    }
}