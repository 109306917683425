.hero {
    height: 100vh;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    backdrop-filter: blur(8px);
    position: relative;
}

.slider {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.slider div {
    width: 100%;
    height: 100vh;
}

.slider div img {
    width: 100%;
    height: 100%;
}

.slider button {
    display: none !important;
}

.hero .right {
    width: 70%;
    padding: 0 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.hero .right h1 {
    font-size: 60px;
    font-weight: 800;
    margin-left: -2px;
}

.hero .left .circle {
    width: 50%;
    height: 450px;
    position: absolute;
    border: 20px solid rgba(255, 255, 255, 0.1);
    opacity: 0.3;
    right: -20px;
    border-radius: 200px;
}

/* Services */
.services {
    width: 100%;
    height: 100vh;
    display: flex;
}

.services {
    position: relative;
}

.services .left {
    width: calc(40% - 50px);
    padding: 0 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    position: relative;
}

.services .left img {
    width: 90%;
    z-index: 100;
}

.services .left img:nth-child(2) {
    width: 50%;
    position: absolute;
    bottom: 10px;
    left: 10%;
    top: 60%;
}

.services .right {
    width: 60%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
}

.services .right h1 {
    line-height: 50px;
    margin-left: -5px;
}

.services .right ul li {
    margin: 10px 0;
    list-style: circle !important;
    margin-left: 50px;
}

/* Service Circle */
.services .left .circle {
    width: 200px;
    height: 200px;
    position: absolute;
    bottom: 15%;
    left: 45%;
    border-radius: 50%;
    border: 20px solid rgba(0, 0, 0, 0.3);
}

/* Industries */
.industries {
    width: 100%;
    height: 500px;
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
}

.industries hr {
    width: 50%;
    margin: 50px 0;
    border-radius: 0 25px 25px 0;
    border: 5px solid var(--black);
}

.industries hr:nth-of-type(1) {
    margin-top: -100px;
}

.industries hr:nth-of-type(2) {
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 25px 0 0 25px;
}

.industries .stage {
    width: 85%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin: 50px auto;
}

.industries .stage .item {
    width: 15%;
    text-align: center;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
    padding: 0;
    border-radius: 50px;
    transition: 0.2s all ease;
    cursor: pointer;
    background-color: var(--grey);
    color: var(--black);
    border: 2px solid var(--black);
}

.industries .stage .item:hover {
    background: var(--black);
    color: white;
    border: 2px solid white;
}

.industries .stage .item i {
    font-size: 22px;
}

/* Blog */
.blogs {
    width: 100%;
    margin: 50px 0 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
}

.blogs .circle {
    width: 40%;
    height: 600px;
    border: 20px solid rgba(255, 255, 255, 0.3);
    border-radius: 50% 50% 50% 50%;
    position: absolute;
    right: -300px;
    top: 18%;
}

.blogs .stage {
    width: 80%;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: auto;
    margin: 20px auto;
}

.blogs .item {
    width: calc(100 * (1/3) - 20px);
    background: var(--grey);
    z-index: 100;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);
    color: var(--black);
}

.blogs .item:nth-of-type(1) {
    height: 90%;
    flex-direction: column;
    align-items: center;
    grid-row: span 2;
}

.blogs .item:nth-of-type(2) {
    height: 75%;
    flex-direction: row;
    align-items: center;
    grid-column: span 2;
}

.blogs .item:nth-of-type(2) .content,
.blogs .item:nth-of-type(3) .content {
    padding: 20px 0;
    text-align: left;
}

.blogs .item:nth-of-type(3) {
    height: 75%;
    flex-direction: row;
    align-items: center;
    grid-column: span 2;
}

.blogs .item img {
    width: 100%;
    height: 200px;
    border-radius: 5px;
}

.blogs .item h2 {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 10px;
}

@media (min-width: 1300px) and (max-width:1350px) {
    .hero .left .circle {
        height: 380px;
    }

    .industries {
        height: auto;
    }

    .industries hr:nth-of-type(2) {
        bottom: -15%;
    }

    .blogs {
        height: auto;
    }
}

@media (min-width: 1008px) and (max-width: 1300px) {
    .hero .left .circle {
        height: 380px;
    }

    .industries {
        height: auto;
    }

    .industries hr:nth-of-type(2) {
        bottom: -15%;
    }

    .blogs {
        height: auto;
    }
}

@media (min-width: 641px) and (max-width: 1007px) {

    .hero,
    .services {
        flex-direction: column-reverse;
        height: auto;
    }

    .hero h1 {
        line-height: 20px;
    }

    .hero {
        padding-top: 90px;
    }

    .hero .left,
    .hero .right {
        width: 50%;
        margin: auto;
        z-index: 100;
    }

    .hero .left {
        margin: 50px auto;
    }

    .services .left,
    .services .right {
        width: 50%;
        margin: 50px auto;
    }

    .services .left img {
        margin: 0;
    }

    .industries hr:nth-of-type(2) {
        bottom: 48px;
    }

    .industries .stage {
        width: 100%;
    }

    .industries .item {
        width: 30%;
    }

    .industries .item i {
        font-size: 18px;
    }

    .blogs {
        height: auto;
    }

    .blogs .stage,
    .blogs .item:nth-of-type(2),
    .blogs .item:nth-of-type(3) {
        display: block;
    }

    .blogs .item:nth-of-type(2) .content,
    .blogs .item:nth-of-type(3) .content {
        text-align: center !important;
    }

    .blogs .item:nth-of-type(2),
    .blogs .item:nth-of-type(3) {
        align-items: center;
    }

    .blogs .stage .item {
        margin: 20px 0;
        align-items: center;
        z-index: 100 !important;
    }

    .blogs .stage .item img {
        width: 50%;
    }

}