.contact {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background: white;
}

.contact hr {
    width: 70%;
    margin: 50px 0 60px 0;
    border-radius: 25px;
    border: 6px solid var(--black);
}

.contact .content {
    width: 70%;
    margin: 20px 0;
    display: flex;
}

.contact form {
    width: 45%;
}

.contact form div {
    display: flex;
    padding: 10px 0;
}

.contact form div label {
    width: 30%;
    padding: 0;
    margin: 0;
    display: block;
    padding: 8px;
}

.contact input,
.contact textarea {
    width: 80%;
    padding: 9px;
    outline: none;
    border: none;
    border-radius: 5px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
    z-index: 100 !important;
    background-color: var(--grey);
    color: var(--black);
}

.contact button {
    width: 100%;
    margin: 10px 0;
    padding: 10px 0;
    background: white;
    border-radius: 5px;
    color: var(--black);
    border: none;
    outline: none;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
    cursor: pointer;
    z-index: 100 !important;
}

.contact button:hover {
    opacity: 0.7;
}

.contact img {
    width: 50%;
    height: 95%;
    border-radius: 5px;
    margin: auto;
}

@media (min-width: 641px) and (max-width: 1007px) {
    .contact .content {
        width: 100%;
        flex-direction: column;
    }

    .contact .content form {
        width: 50%;
        margin: auto;
    }

    .contact .content img {
        display: none;
    }
}