.hero {
    height: 100vh;
    display: flex;
}

.hero .left,
.hero .right {
    width: 48%;
    padding: 2%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    position: relative;
}

.hero .right img {
    z-index: 100;
}

.hero .right .circle {
    width: 400px;
    height: 400px;
    background: var(--grey);
    border-radius: 50%;
    position: absolute;
    right: 18%;
    bottom: 20%;
    z-index: 90;
}

.hero .left span {
    color: var(--grey);
}

.hero .left h1,
.whyChooseUs .right h1 {
    line-height: 50px;
    text-transform: uppercase;
}

.whyChooseUs {
    height: 100vh;
    display: flex;
}

.whyChooseUs .right,
.whyChooseUs .left {
    width: 48%;
    height: 100%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    position: relative;
}

.whyChooseUs .left .imgBorderTop {
    width: 200px;
    height: 200px;
    border-top: 10px solid var(--grey);
    border-right: 10px solid var(--grey);
    position: absolute;
    top: 10%;
    right: 10%;
}

.whyChooseUs .left .imgBorderBottom {
    width: 200px;
    height: 200px;
    border-top: 10px solid var(--grey);
    border-right: 10px solid var(--grey);
    position: absolute;
    bottom: 6%;
    left: 20%;
    transform: rotate(180deg);
}

.whyChooseUs .right {
    padding-right: 100px;
}

.industries {
    width: 100%;
    height: 100vh;
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.industries hr {
    width: 50%;
    margin: 50px 0;
    border-radius: 0 25px 25px 0;
    border: 5px solid var(--grey);
}

.industries hr:nth-of-type(2) {
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 25px 0 0 25px;
}

.industries .stage {
    width: 85%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin: 50px auto;
}

.industries .stage .item {
    width: 15%;
    text-align: center;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
    padding: 0;
    border-radius: 50px;
    transition: 0.2s all ease;
    cursor: pointer;
    background-color: var(--grey);
    color: var(--black);
    border: 2px solid var(--black);
}

.industries .stage .item:hover {
    background: var(--black);
    border: 2px solid white;
    color: white;
}

.industries .stage .item i {
    font-size: 22px;
}

.services {
    width: 100%;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.services h1 {
    text-transform: uppercase;
    margin: 20px 0 40px 0;
    text-align: center;
    line-height: 50px;
}

.services span {
    color: var(--grey);
}

.services .stage {
    width: 95%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
    position: relative;
}

.services .card {
    width: 35%;
    height: 500px;
    text-align: center;
    padding: 1%;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 100;
    background: var(--grey);
    position: relative;
    color: var(--black);
    position: relative;
}

.services .card img {
    width: 100%;
    height: 200px;
    text-align: center;
}

.services .card .lottiePlayer {
    width: 100%;
    height: 200px;
    text-align: center;
}

.services .card h2 {
    line-height: 30px;
    font-size: 25px;
}

.services button {
    border-radius: 25px;
    border: 2px solid black;
    font-size: 15px;
    padding: 1% 2%;
    background-color: black;
    color: white;
    position: absolute;
    bottom: 20px;
    left: 39%;
    cursor: pointer;
}

@media (min-width: 1008px) and (max-width: 1300px) {
    .hero .right .circle {
        width: 350px;
        height: 350px;
    }
}

@media (min-width: 641px) and (max-width: 1007px) {

    .hero,
    .whyChooseUs {
        flex-direction: column;
        height: auto;
        padding: 90px 0;
    }

    .hero .left,
    .hero .right {
        width: 50%;
        margin: auto;
        gap: 0px;
    }

    .hero .right .circle {
        width: 350px;
        height: 350px;
        right: 10%;
        top: 10%;
        bottom: 0%;
    }

    .whyChooseUs {
        flex-direction: column-reverse;
        height: auto;
        padding: 90px 0;
    }

    .whyChooseUs .right,
    .whyChooseUs .left {
        width: 50%;
        margin: auto;
    }

    .whyChooseUs .left .imgBorderTop {
        width: 100px;
        height: 100px;
        top: 0;
    }

    .whyChooseUs .left .imgBorderBottom {
        width: 100px;
        height: 100px;
        bottom: -5%;
    }

    .industries hr:nth-of-type(2) {
        bottom: -20%;
    }

    .services .stage {
        flex-direction: column;
    }

    .services .stage .card {
        width: 100%;
        text-align: center;
    }

    .services .stage .card img {
        width: 40%;
        margin: auto;
    }
}