@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;900&display=swap');
@import "./shared/css/animation.css";
@import "./shared/css/shapes.css";

:root {
  --grey: #D9DADF;
  --black: #393939;
}

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  color: inherit;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  list-style: none;
  word-break: keep-all;
}

h1 {
  font-size: 40px;
  text-transform: uppercase;
  color: var(--black);
}

h2 {
  font-size: 30px;
  color: var(--black);
}

h3 {
  color: var(--black);
  font-size: 20px;
}

h4 {
  color: var(--black);
  font-size: 16px;
}

p,
li,
button {
  font-size: 16px;
}

main {
  width: 100%;
  overflow: hidden;
}

.uppercase {
  text-transform: uppercase;
}

#tsparticles {
  width: 100%;
  height: 100px;
  position: absolute;
}

b {
  color: var(--black);
}

@media (min-width: 1008px) and (max-width: 1300px) {
  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 15px;
  }

  p,
  li,
  button {
    font-size: 12px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  h1 {
    font-size: 30px !important;
  }

  h2 {
    font-size: 18px !important;
  }

  h3 {
    font-size: 15px !important;
  }

  h4 {
    font-size: 12px !important;
  }

  p,
  li,
  button {
    font-size: 10px !important;
  }

  .display-none {
    display: none;
  }
}